import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';
import gsap from 'gsap';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import ImageGallery from '../../../../../components/ImageGallery/ImageGallery';
import styles from './UpsellV2.module.scss';
import * as events from '../../../../../lib/analytics/events';
import SubscriptionAppBar from '../../../components/SubscriptionAppBar';
import { IImage } from '../../../../../types';
import { Image } from '../../../../../components/Image';
import { ReactComponent as AddIcon } from '../../../../../assets/images/icons/add.svg';
import { ReactComponent as MinusIcon } from '../../../../../assets/images/icons/minus.svg';
import { ReactComponent as DigestiveIcon } from '../../../../../assets/images/icons/supplements_upsell/digestive.svg';
import { ReactComponent as BoneAndJointIcon } from '../../../../../assets/images/icons/supplements_upsell/bone-and-joint.svg';
import { ReactComponent as BrainFunctionIcon } from '../../../../../assets/images/icons/supplements_upsell/brain.svg';
import { ReactComponent as HeartHealthIcon } from '../../../../../assets/images/icons/supplements_upsell/heart.svg';
import { ReactComponent as CartilageIcon } from '../../../../../assets/images/icons/supplements_upsell/cartilage.svg';
import { ReactComponent as HipFlexibilityIcon } from '../../../../../assets/images/icons/supplements_upsell/hip-flexibility.svg';
import { ReactComponent as JointMobilityIcon } from '../../../../../assets/images/icons/supplements_upsell/joint-mobility.svg';
import { ReactComponent as RepairAndRecoveryIcon } from '../../../../../assets/images/icons/supplements_upsell/repair-and-recovery.svg';
import { ReactComponent as ShinyFurIcon } from '../../../../../assets/images/icons/supplements_upsell/shiny.svg';
import { ReactComponent as AntioxidantIcon } from '../../../../../assets/images/icons/supplements_upsell/antioxidant.svg';
import { ReactComponent as SkinBarrierIcon } from '../../../../../assets/images/icons/supplements_upsell/skin-barrier.svg';
import { ReactComponent as SensitiveSkinIcon } from '../../../../../assets/images/icons/supplements_upsell/sensitive.svg';
import { ReactComponent as StressIcon } from '../../../../../assets/images/icons/supplements_upsell/stress.svg';
import { ReactComponent as RelaxationIcon } from '../../../../../assets/images/icons/supplements_upsell/relaxation.svg';
import { ReactComponent as RestfulIcon } from '../../../../../assets/images/icons/supplements_upsell/restful.svg';
import { ReactComponent as ResilienceIcon } from '../../../../../assets/images/icons/supplements_upsell/resilience.svg';
import { ReactComponent as SkinHealthIcon } from '../../../../../assets/images/icons/supplements_upsell/skin-health.svg';
import { ReactComponent as ExerciseAndActivityIcon } from '../../../../../assets/images/icons/supplements_upsell/exercise.svg';
import { ReactComponent as KidneyAndLiverIcon } from '../../../../../assets/images/icons/supplements_upsell/kidney.svg';
import { ReactComponent as StarIcon } from '../../../../../assets/images/icons/star.svg';
import { ReactComponent as ArrowAngleIcon } from '../../../../../assets/images/icons/arrow_angle.svg';
import { ReactComponent as ReturnsIcon } from '../../../../../assets/images/icons/returns.svg';
import { ReactComponent as ShippingIcon } from '../../../../../assets/images/icons/shipping.svg';
import { ReactComponent as MuscleIcon } from '../../../../../assets/images/icons/supplements_upsell/muscle.svg';
import FAQ from '../../../../Checkout/components/Supplements/ProductInfo/FAQ';
import DeliveryFrequencyModal from '../DeliveryFrequencyModal/DeliveryFrequencyModal';
import InAppCheckoutModal from '../InAppCheckoutModal/InAppCheckoutModal';
import * as types from '../../../../../types';
import { formattedCentsToDollars, getLoadingOrErrorElement } from '../../../../../lib/util';
import {
  addMultipleSupplementSubscriptionsMutation,
  supplementsSubscriptionsQuery,
} from '../../../../../graphql-operations';
import AppPaths from '../../../../../AppPaths';
import { ChangeType, SplashPageState, Toast } from '../../SplashPage';
import { ConfirmModal } from '../../../../../components/modals';
import { getFiGrowthBook } from '../../../../../lib/growthbook';

export enum SKUType {
  Formula = '8-in-1 Formula',
  HipJoint = 'Hip & Joint',
  SkinCoat = 'Skin & Coat',
  Calming = 'Calming',
}

export enum PurchaseType {
  Autoship = 'Autoship',
  OneTime = 'One Time',
}

interface UpsellProps {
  referrer?: 'add' | 'upsell';
  closeUpsell?: () => void;
}

export interface SupplementCartItem {
  skuType: SKUType;
  purchaseType: PurchaseType;
  eligibleForDiscount: boolean;
  frequency?: number;
}

export const SUPPLEMENT_BAG_IMAGES: Record<SKUType, IImage> = {
  [SKUType.Formula]: {
    type: 'image',
    url: '/product_images/supplements/upsell-v2/formula-bag.png',
  },
  [SKUType.Calming]: {
    type: 'image',
    url: '/product_images/supplements/upsell-v2/calming-bag.png',
  },
  [SKUType.HipJoint]: {
    type: 'image',
    url: '/product_images/supplements/upsell-v2/hipjoint-bag.png',
  },
  [SKUType.SkinCoat]: {
    type: 'image',
    url: '/product_images/supplements/upsell-v2/skincoat-bag.png',
  },
};

export const SUPPLEMENT_KEY_BENEFITS: Record<
  SKUType,
  Array<{ image: JSX.Element; title: string; description: string }>
> = {
  [SKUType.Formula]: [
    {
      image: <DigestiveIcon />,
      title: 'Digestive System',
      description: 'Turmeric Root Powder and Thiamin support normal gut function.',
    },
    {
      image: <BoneAndJointIcon />,
      title: 'Bone & Joint',
      description: 'Glucosamine, MSM and Chondroitin maintain bone health and joint mobility.',
    },
    {
      image: <BrainFunctionIcon />,
      title: 'Brain Function',
      description: 'Thiamin, Vitamin B6 and Vitamin B12 support cognitive functioning.',
    },
    {
      image: <HeartHealthIcon />,
      title: 'Heart Health',
      description: 'Vitamin C and Zinc support the cardiovascular system.',
    },
  ],
  [SKUType.HipJoint]: [
    {
      image: <CartilageIcon />,
      title: 'Cartilage Health',
      description: 'Vitamin C helps maintain strong connective tissue.',
    },
    {
      image: <HipFlexibilityIcon />,
      title: 'Hip Flexibility',
      description: 'Green-lipped Mussel provides glycosaminoglycans (GAGs) to support normal range of motion.',
    },
    {
      image: <JointMobilityIcon />,
      title: 'Joint Mobility',
      description: 'Krill, Glucosamine HCl, Chondroitin and MSM enhance joint health and reduce occasional stiffness.',
    },
    {
      image: <RepairAndRecoveryIcon />,
      title: 'Repair & Recovery',
      description: 'Zinc supports natural healing processes and overall immune function.',
    },
  ],
  [SKUType.SkinCoat]: [
    {
      image: <ShinyFurIcon />,
      title: 'Shiny & Healthy Fur',
      description: 'Omega-3 Fatty Acids from fish oil promote soft, glossy coats.',
    },
    {
      image: <AntioxidantIcon />,
      title: 'Antioxidant Support',
      description: 'Vitamin C and Vitamin E help maintain healthy skin cells and normal collagen production.',
    },
    {
      image: <SkinBarrierIcon />,
      title: 'Skin Barrier Health',
      description: 'Biotin and Zinc help maintain skin barrier to protect against environmental irritants.',
    },
    {
      image: <SensitiveSkinIcon />,
      title: 'Sensitive Skin',
      description: 'Essential vitamins and nutrients promote normal inflammatory responses.',
    },
  ],
  [SKUType.Calming]: [
    {
      image: <StressIcon />,
      title: 'Stress & Anxiety Management',
      description: 'Valerian Root and L-theanine promote relaxation and healthy nervous system function.',
    },
    {
      image: <RelaxationIcon />,
      title: 'Relaxation Without Sedation',
      description: 'CBD and Magnesium support calmness while maintaining alertness.',
    },
    {
      image: <RestfulIcon />,
      title: 'Restful Sleep',
      description: 'Melatonin and L-tryptophan promote restorative naps and sleep.',
    },
    {
      image: <ResilienceIcon />,
      title: 'Resilience in Stressful Situations',
      description: 'Thiamine helps them maintain composure during anxiety-inducing events.',
    },
  ],
};

export const ALL_BENEFITS: Record<
  SKUType,
  { benefits: Array<{ image: JSX.Element; title: string; description: string }>; ingredients: string }
> = {
  [SKUType.Formula]: {
    benefits: [
      {
        image: <DigestiveIcon />,
        title: 'Digestive System',
        description: 'Turmeric Root Powder and Thiamin support normal gut function.',
      },
      {
        image: <BoneAndJointIcon />,
        title: 'Bone & Joint',
        description: 'Glucosamine, MSM and Chondroitin maintain bone health and joint mobility.',
      },
      {
        image: <BrainFunctionIcon />,
        title: 'Brain Function',
        description: 'Thiamin, Vitamin B6 and Vitamin B12 support cognitive functioning.',
      },
      {
        image: <HeartHealthIcon />,
        title: 'Heart Health',
        description: 'Vitamin C and Zinc support the cardiovascular system.',
      },
      {
        image: <MuscleIcon />,
        title: 'Muscle Growth',
        description: 'Vitamin B6, Vitamin B12 and Vitamin D support physical performance.',
      },
      {
        image: <SkinHealthIcon />,
        title: 'Skin Health',
        description: 'Biotin, Zinc and Collagen support skin cell health.',
      },
      {
        image: <ExerciseAndActivityIcon />,
        title: 'Exercise & Activity',
        description: 'Manganese and Zinc help maintain normal metabolism and nervous system processing.',
      },
      {
        image: <KidneyAndLiverIcon />,
        title: 'Kidney & Liver',
        description: 'Turmeric Root and Vitamin E promote overall well-being.',
      },
    ],
    ingredients: `All ingredients: Glucosamine HCl, Chondroitin Sulfate Sodium, Hydrolyzed Collagen, Methylsulfonylmethane (MSM), Silicon Dioxide, Turmeric Root Powder, Brewer's Yeast, Natural Vegetarian Flavor, Manganese, Zinc, Vitamin A (as Beta-Carotene), Vitamin B1, Thiamin (as Thiamin HCl), Vitamin B2 (Riboflavin), Vitamin B3 (Niacin), Vitamin B5 Pantothenic Acid ( as Calcium-D-Pantothenate), Vitamin B6 (as Pyridoxine HCl), Vitamin B7 (Biotin), Vitamin B9 (Folic Acid), Vitamin B12 (as Cyanobalamin), Vitamin C (Ascorbic Acid), Vitamin D (as Cholecalciferol), Vitamin E (as D-Alpha Tocopheryl Acetate)`,
  },
  [SKUType.HipJoint]: {
    benefits: [
      {
        image: <CartilageIcon />,
        title: 'Cartilage Health',
        description: 'Vitamin C helps maintain strong connective tissue.',
      },
      {
        image: <HipFlexibilityIcon />,
        title: 'Hip Flexibility',
        description: 'Green-lipped Mussel provides glycosaminoglycans (GAGs) to support normal range of motion.',
      },
      {
        image: <JointMobilityIcon />,
        title: 'Joint Mobility',
        description:
          'Krill, Glucosamine HCl, Chondroitin and MSM enhance joint health and reduce occasional stiffness.',
      },
      {
        image: <RepairAndRecoveryIcon />,
        title: 'Repair & Recovery',
        description: 'Zinc supports natural healing processes and overall immune function.',
      },
    ],
    ingredients:
      'All ingredients: Glucosamine HCl, Methylsulfonylmethane (MSM), Krill Green Lipped Mussel, Vitamin C (Ascorbic Acid), Chondroitin Sulfate, Sodium Hyaluronate, Zinc (Zinc Amino Acid Chelate), Brewer’s Yeast Powder, Flax Oil (Processing Aid), Natural Vegetarian Flavor, Silicon Dioxide',
  },
  [SKUType.SkinCoat]: {
    benefits: [
      {
        image: <ShinyFurIcon />,
        title: 'Shiny & Healthy Fur',
        description: 'Omega-3 Fatty Acids from fish oil promote soft, glossy coats.',
      },
      {
        image: <AntioxidantIcon />,
        title: 'Antioxidant Support',
        description: 'Vitamin C and Vitamin E help maintain healthy skin cells and normal collagen production.',
      },
      {
        image: <SkinBarrierIcon />,
        title: 'Skin Barrier Health',
        description: 'Biotin and Zinc help maintain skin barrier to protect against environmental irritants.',
      },
      {
        image: <SensitiveSkinIcon />,
        title: 'Sensitive Skin',
        description: 'Essential vitamins and nutrients promote normal inflammatory responses.',
      },
    ],
    ingredients:
      'All ingredients: Fish Oil Powder, Vitamin C (Ascorbic Acid), Natural Vitamin E Supplement, Turmeric, Zinc (Zinc Amino Acid Chelate), Vitamin B7 (Biotin), Brewer’s Yeast Powder, Natural Vegetarian Flavor, Silicon Dioxide, Flax Oil (processing aid)',
  },
  [SKUType.Calming]: {
    benefits: [
      {
        image: <StressIcon />,
        title: 'Stress & Anxiety Management',
        description: 'Valerian Root and L-theanine promote relaxation and healthy nervous system function.',
      },
      {
        image: <RelaxationIcon />,
        title: 'Relaxation Without Sedation',
        description: 'CBD and Magnesium support calmness while maintaining alertness.',
      },
      {
        image: <RestfulIcon />,
        title: 'Restful Sleep',
        description: 'Melatonin and L-tryptophan promote restorative naps and sleep.',
      },
      {
        image: <ResilienceIcon />,
        title: 'Resilience in Stressful Situations',
        description: 'Thiamine helps them maintain composure during anxiety-inducing events.',
      },
    ],
    ingredients:
      'All ingredients: Valerian Root Powder, L-Theanine, Thiamine (as Thiamine HCl), L-Tryptophan, Magnesium Proteinate, *Broad Spectrum Hemp, Melatonin, Brewer’s Yeast Powder, Natural Vegetarian Flavor, Silicon Dioxide',
  },
};

const RECURLY_ERROR_ADDENDUM = 'Please use a different card or contact your bank.';

export const AllBenefits: React.FC<{ skuType: SKUType }> = ({ skuType }) => {
  const { benefits, ingredients } = ALL_BENEFITS[skuType];

  return (
    <div className={styles.benefitContainer}>
      <div className={styles.benefitContainerSubtitle}>{skuType} BENEFITS</div>
      <div className={styles.benefitContainerTitle}>Formulated for...</div>
      <div className={styles.benefitItemContainer}>
        {benefits.map((benefit, index) => (
          <div key={index} className={styles.benefitItem}>
            <div className={styles.benefitImage}>{benefit.image}</div>
            <div className={styles.benefitText}>
              <div className={styles.benefitTitle}>{benefit.title}</div>
              <div className={styles.benefitDescription}>{benefit.description}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.ingredientsTitle}>What’s Inside</div>
      <div className={styles.ingredients}>
        <span className={styles.ingredientsBold}>All ingredients: </span>
        {ingredients}
      </div>
      <div className={styles.ingredientsDisclaimer}>
        This product is not intended to diagnose, treat, cure or prevent any disease.
      </div>
    </div>
  );
};

const KeyBenefits: React.FC<{
  selectedSKUType: SKUType;
  skuType: SKUType;
}> = ({ selectedSKUType, skuType }) => {
  const initialSKUType = getInitialSKUTypeFromURL();
  const [keyBenefitsExpanded, setKeyBenefitsExpanded] = useState<boolean>(initialSKUType === skuType);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      if (keyBenefitsExpanded) {
        gsap.to(containerRef.current, { height: 'auto', opacity: 1, duration: 0.3 });
      } else {
        gsap.to(containerRef.current, { height: 0, opacity: 0, duration: 0.3 });
      }
    }
  }, [keyBenefitsExpanded]);

  useEffect(() => {
    if (selectedSKUType !== skuType) {
      setKeyBenefitsExpanded(false);
    }
  }, [selectedSKUType, skuType]);

  return (
    <div>
      <div
        ref={containerRef}
        style={{ overflow: 'hidden', height: 0, opacity: 0 }}
        className={styles.skuOptionKeyBenefitContainer}
      >
        <div className={styles.skuOptionKeyBenefitCollapseButton} onClick={() => setKeyBenefitsExpanded(false)}>
          <MinusIcon /> Collapse key benefits
        </div>
        {SUPPLEMENT_KEY_BENEFITS[selectedSKUType].map((benefit, index) => (
          <div key={index} className={styles.skuOptionKeyBenefitItem}>
            <div className={styles.skuOptionKeyBenefitItemImageContainer}>{benefit.image}</div>
            <div className={styles.skuOptionKeyBenefitItemText}>
              <div className={styles.skuOptionKeyBenefitItemTitle}>{benefit.title}</div>
              <div className={styles.skuOptionKeyBenefitItemDescription}>{benefit.description}</div>
            </div>
          </div>
        ))}
      </div>
      {selectedSKUType === skuType && !keyBenefitsExpanded && (
        <div className={styles.skuOptionKeyBenefitExpandButton} onClick={() => setKeyBenefitsExpanded(true)}>
          <AddIcon /> See key benefits
        </div>
      )}
    </div>
  );
};

export const SKU_OPTION_DETAILS: Record<SKUType, { title: string; description: string }> = {
  [SKUType.Formula]: {
    title: '8-in-1 Formula',
    description: "Covers the main areas of your dog's health.",
  },
  [SKUType.HipJoint]: {
    title: 'Hip & Joint',
    description: 'Maintains mobility and flexibility.',
  },
  [SKUType.SkinCoat]: {
    title: 'Skin & Coat',
    description: 'Promotes a vibrant and healthy coat.',
  },
  [SKUType.Calming]: {
    title: 'Calming',
    description: 'Supports relaxation and resilience.',
  },
};

export const SKUOption: React.FC<{
  skuType: SKUType;
  selectedSKUType: SKUType;
  onSelect: (skuType: SKUType) => void;
  fromInsights?: boolean;
  petName?: string;
  recommended?: SKUType;
}> = ({
  skuType,
  selectedSKUType,
  onSelect,
  fromInsights = false,
  petName = 'your dog',
  recommended = SKUType.Formula,
}) => {
  const { title, description } = SKU_OPTION_DETAILS[skuType];
  return (
    <>
      {fromInsights && recommended === skuType && (
        <span
          className={classNames(styles.skuRecommended, {
            [styles.skuRecommendedSelected]: selectedSKUType === skuType,
          })}
          onClick={() => onSelect(skuType)}
        >
          Recommended for {petName}
        </span>
      )}
      <div
        className={classNames(styles.skuOption, {
          [styles.skuOptionSelected]: selectedSKUType === skuType,
          [styles.insights]: fromInsights && recommended === skuType,
        })}
        onClick={() => onSelect(skuType)}
      >
        <div className={styles.skuOptionContentContainer}>
          <Image className={styles.skuOptionImage} image={SUPPLEMENT_BAG_IMAGES[skuType]} />
          <div className={styles.skuOptionText}>
            <div className={styles.skuOptionTitle}>{title}</div>
            <div className={styles.skuOptionDescription}>{description}</div>
          </div>
        </div>
        <KeyBenefits selectedSKUType={selectedSKUType} skuType={skuType} />
      </div>
    </>
  );
};

export const Reviews: React.FC = () => {
  return (
    <div className={styles.reviewsContainer}>
      <div className={styles.reviewsTitle}>What they’re saying</div>
      <div className={styles.reviewsTitleDesktop}>Reviews</div>
      <div className={styles.heroReviewItem}>
        <div className={styles.reviewRating}>
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </div>
        <div className={styles.reviewText}>“This super picky doodle is a big fan.”</div>
        <div className={styles.reviewTextDesktop}>“Super picky doodle is a big fan.”</div>
      </div>
      <div className={styles.smallReviewContainer}>
        <div className={styles.reviewItem}>
          <div className={styles.reviewRating}>
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </div>
          <div className={styles.reviewText}>
            “[With Fi supplements],{' '}
            <span className={styles.reviewTextBold}>we notice an incredible increase in energy and excitement.</span> If
            we skip a meal or two and forget we can tell. This is a game changer for overweight dogs that need a little
            energy.”
          </div>
        </div>
        <div className={styles.reviewItem}>
          <div className={styles.reviewRating}>
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </div>
          <div className={styles.reviewText}>
            “My dogs are really picky with what vitamins they eat.{' '}
            <span className={styles.reviewTextBold}>These they lap up as if it’s their favorite treat!!</span>”
          </div>
        </div>
        <div className={styles.reviewItem}>
          <div className={styles.reviewRating}>
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </div>
          <div className={styles.reviewText}>
            “We tried chewables before but Luna would just taste them and then spit them out so we needed a powder that
            we could mix up into her food.{' '}
            <span className={styles.reviewTextBold}>
              So far she hasn’t noticed a thing, and has even started eating the food we weren’t sure she liked!
            </span>
            ”
          </div>
        </div>
      </div>
    </div>
  );
};

export const PurchaseContainer: React.FC<{
  purchaseType: PurchaseType | null;
  setPurchaseType: React.Dispatch<React.SetStateAction<PurchaseType | null>>;
  autoshipFrequency: number;
  setAutoshipFrequency: React.Dispatch<React.SetStateAction<number>>;
  isNextItemEligibleForFirstBagDiscount: boolean;
  autoshipOnly?: boolean;
  fromInsights?: boolean;
  petName?: string;
}> = ({
  purchaseType,
  setPurchaseType,
  autoshipFrequency,
  setAutoshipFrequency,
  isNextItemEligibleForFirstBagDiscount,
  autoshipOnly = false,
  fromInsights = false,
  petName = 'your dog',
}) => {
  const showFiveDollarOffer = getFiGrowthBook().getFeatureValue<boolean>('supplements-five-dollar-first-bag', false);
  return (
    <div className={styles.purchaseContainer}>
      {!autoshipOnly && <div className={styles.purchaseTitle}>How do you want to purchase?</div>}
      {!autoshipOnly && (
        <div className={styles.purchaseOptionContainer}>
          <div
            className={classNames(styles.buyOncepurchaseOption, {
              [styles.purchaseOptionSelected]: purchaseType === PurchaseType.OneTime,
            })}
            onClick={() => setPurchaseType(PurchaseType.OneTime)}
          >
            <div className={styles.purchaseOptionText}>
              <div className={styles.purchaseOptionTitle}>Buy Once</div>
            </div>
            <div className={styles.purchaseOptionPrice}>$30</div>
          </div>
          <div
            className={classNames(styles.autoshipPurchaseOption, {
              [styles.purchaseOptionSelected]: purchaseType === PurchaseType.Autoship,
            })}
            onClick={() => setPurchaseType(PurchaseType.Autoship)}
          >
            {isNextItemEligibleForFirstBagDiscount && (
              <div className={styles.purchaseOptionAutoshipUpsell}>
                First bag special! Extra $10 OFF applied at checkout
              </div>
            )}
            <div className={styles.autoshipPurchaseOptionContent}>
              <div className={styles.purchaseOptionText}>
                <div className={styles.purchaseOptionTitleAutoship}>Auto-ship</div>
                <div className={styles.purchaseOptionDescription}>Subscribe and Save $5/bag</div>
              </div>
              <div className={styles.purchaseOptionPrice}>$25</div>
            </div>
          </div>
        </div>
      )}
      {purchaseType === PurchaseType.Autoship && (
        <>
          <div className={styles.frequencyTitle}>
            {fromInsights
              ? `How often do you want deliveries for ${petName}?`
              : autoshipOnly
              ? 'Auto-ship frequency'
              : 'Select your delivery frequency'}
          </div>
          <div className={styles.frequencyContainer}>
            <button
              className={classNames(styles.frequencyMinusButton, {
                [styles.frequencyMinusButtonDisabled]: autoshipFrequency === 1,
              })}
              onClick={() => setAutoshipFrequency(Math.max(autoshipFrequency - 1, 1))}
            >
              <MinusIcon />
            </button>
            <div className={styles.frequencyValue}>
              {autoshipFrequency}{' '}
              <span className={styles.frequencyValueUnit}>{autoshipFrequency === 1 ? 'week' : 'weeks'}</span>
            </div>
            <button
              className={classNames(styles.frequencyPlusButton, {
                [styles.frequencyPlusButtonDisabled]: autoshipFrequency === 12,
              })}
              onClick={() => setAutoshipFrequency(Math.min(autoshipFrequency + 1, 12))}
            >
              <AddIcon />
            </button>
          </div>
          {autoshipOnly && !showFiveDollarOffer && (
            <div className={styles.autoshipPricing}>
              {formattedCentsToDollars(2500 / autoshipFrequency)}/week billed as {formattedCentsToDollars(2500)} every{' '}
              {autoshipFrequency} weeks
            </div>
          )}
          {fromInsights ? (
            <p>
              We suggest every <span className={styles.recommendedInsightFrequency}>6 weeks</span> based on {petName}'s
              breed, size, and activity level—feel free to adjust.
            </p>
          ) : (
            <DeliveryFrequencyModal
              onContinue={(frequency: number) => setAutoshipFrequency(frequency)}
              trigger={
                <div className={styles.whatIsRightContainer}>
                  <div className={styles.whatIsRightTitle}>What is right for your dog?</div>
                  <div className={styles.whatIsRightIcon}>
                    <ArrowAngleIcon />
                  </div>
                </div>
              }
            />
          )}
          <div className={styles.freeGroundShippingContainer}>
            <div className={styles.freeGroundShippingIcon}>
              <ShippingIcon />
            </div>
            <div className={styles.freeGroundShippingTitle}>Free Ground Shipping</div>
          </div>
          <div className={styles.tryRiskFreeContainer}>
            <div className={styles.tryRiskFreeIcon}>
              <ReturnsIcon />
            </div>
            <div className={styles.tryRiskFreeTitle}>Try risk free, cancel anytime</div>
          </div>
        </>
      )}
    </div>
  );
};

const baseSkuMap: Record<SKUType, string> = {
  [SKUType.Formula]: 'f2-v-mv-180',
  [SKUType.HipJoint]: 'f1-v-hj-180',
  [SKUType.SkinCoat]: 'f1-v-sc-180',
  [SKUType.Calming]: 'f1-v-cc-180',
};

export function getSKUForCartItem(item: SupplementCartItem): string {
  const baseSku = baseSkuMap[item.skuType];
  if (item.purchaseType === PurchaseType.OneTime) {
    return baseSku;
  }

  const frequency = `${item.frequency!}-week`;
  return `${baseSku}-${frequency}-no-trial`;
}

export function getCartSKUs(cartItems: SupplementCartItem[]): string[] {
  return cartItems.map((item) => getSKUForCartItem(item));
}

// Returns associated image index for supplements PDP
export const skuIntegerImageMap: Record<SKUType, number> = {
  [SKUType.Formula]: 1,
  [SKUType.HipJoint]: 2,
  [SKUType.SkinCoat]: 3,
  [SKUType.Calming]: 4,
};

// Returns associated image index for supplements upsell
export const skuToImageIndex: Record<SKUType, number> = {
  [SKUType.Formula]: 1,
  [SKUType.SkinCoat]: 2,
  [SKUType.Calming]: 3,
  [SKUType.HipJoint]: 4,
};

export const urlTypeParamToSkuType: Record<string, SKUType> = {
  '8-in-1_formula': SKUType.Formula,
  hip_joint: SKUType.HipJoint,
  skin_coat: SKUType.SkinCoat,
  calming: SKUType.Calming,
};

export function getInitialSKUTypeFromURL(): SKUType | null {
  const url = new URL(window.location.href);
  const skuType = urlTypeParamToSkuType[url.searchParams.get('type') ?? ''];
  return skuType ? skuType : null;
}

export default function Upsell({ referrer, closeUpsell }: UpsellProps) {
  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useQuery<types.gqlTypes.ECOMMERCE_getSupplementSubscriptionsQuery>(supplementsSubscriptionsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const location = useLocation<SplashPageState>();
  const [showToast, setShowToast] = useState(false);
  const [toastChange, setToastChange] = useState<ChangeType | null>(null);
  const locationToastChange = location.state?.toast?.change ?? null;

  useEffect(() => {
    if (locationToastChange) {
      setShowToast(true);
      setToastChange(locationToastChange);
    }
  }, [locationToastChange]);

  const initialSkuType = getInitialSKUTypeFromURL() ?? SKUType.Formula;
  const [selectedSKUType, setSelectedSKUType] = useState<SKUType>(initialSkuType);
  const [recommendedSkuType, _setRecommendedSkuType] = useState<SKUType>(initialSkuType);
  const [purchaseType, setPurchaseType] = useState<PurchaseType | null>(PurchaseType.Autoship);
  const [autoshipFrequency, setAutoshipFrequency] = useState<number>(4);
  const [fromInsights, setFromInsights] = useState<boolean>(false);
  const [petName, setPetName] = useState<string>('');
  const [initialImageCarouselNumber, setInitialImageCarouselNumber] = useState<number>(0);

  useEffect(() => {
    const url = new URL(window.location.href);

    // If insight URL param is set to true, that means users are coming to this page from supplement insights in-app.
    const insightParam = url.searchParams.get('insight');
    const insights = insightParam ? JSON.parse(insightParam) : false;
    const nameParam = url.searchParams.get('pet_name') ?? 'your dog';
    const MAX_NAME_LENGTH = 16;
    const name = nameParam.length <= MAX_NAME_LENGTH ? nameParam : 'your dog';
    setFromInsights(insights);
    setPetName(name);
    insights && setAutoshipFrequency(6);
    // If user is coming from insights, carousel image should default to the image that matches the preselected type.
    insights && setInitialImageCarouselNumber(skuToImageIndex[initialSkuType]);
  }, [initialSkuType]);

  useEffect(() => {
    const url = new URL(window.location.href);

    url.searchParams.set(
      'type',
      Object.keys(urlTypeParamToSkuType).find((key) => urlTypeParamToSkuType[key] === selectedSKUType) || '',
    );
    window.history.replaceState({}, '', url.toString());
  }, [selectedSKUType]);

  const [cartModalOpen, setCartModalOpen] = useState<boolean>(false);
  const [cartItems, setCartItems] = useState<SupplementCartItem[]>([]);
  const isEligibleForFirstBagDiscount = subscriptionData?.currentUser.billingAccount?.subscriptions.length === 0;
  const cartContainsDiscountEligibleItems = cartItems.some((item) => item.eligibleForDiscount);
  const isNextItemEligibleForFirstBagDiscount = isEligibleForFirstBagDiscount && !cartContainsDiscountEligibleItems;
  const handleRemoveItem = (index: number, close: () => void) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);

    if (updatedCartItems.length === 0) {
      close();
    }
  };
  const userHasPaymentInfo = subscriptionData?.currentUser.billingAccount?.billingInfo !== null && !subscriptionError;

  const [billingError, setBillingError] = useState<string | null>(null);

  const history = useHistory();
  const goToSupplementsHomeWithSuccess = (refetch: boolean) => {
    setCartItems([]);
    closeUpsell && closeUpsell();
    history.push({
      pathname: AppPaths.Supplements.Root,
      search: window.location.search,
      state: { toast: { success: true, change: 'added' }, refetch },
    });
  };

  const [purchaseMutation, purchaseMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_addMultipleSupplementSubscriptions,
    types.gqlTypes.ECOMMERCE_addMultipleSupplementSubscriptionsVariables
  >(addMultipleSupplementSubscriptionsMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (mutationData) => {
      const success = !!mutationData.addMultipleSupplementSubscriptions?.success;
      if (!success) {
        throw new Error(
          mutationData.addMultipleSupplementSubscriptions?.reasonForFailure ??
            'Unable to purchase subscription. Please contact customer support',
        );
      } else {
        events.supplements.purchaseSucceeded('in-app');
        goToSupplementsHomeWithSuccess(false);
      }
    },
  });

  const loadingOrErrorElement = getLoadingOrErrorElement(subscriptionLoading, null);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }
  const purchaseLoading = getLoadingOrErrorElement(purchaseMutationState.loading, null);
  if (purchaseLoading) {
    return purchaseLoading;
  }

  return (
    <>
      <SubscriptionAppBar
        title={'Supplements'}
        backButtonExitsWebview={referrer === 'upsell' ? true : false}
        backButtonAnalyticsEvent={() => events.supplementsManagement.goBackFromPage({}, 'Upsell')}
        closeButtonAnalyticsEvent={() => events.supplementsManagement.closePage({}, 'Upsell')}
        forceBackButtonBehavior={referrer === 'add' ? closeUpsell : undefined}
        noCloseButton={true}
        shoppingBagValue={cartItems.length}
        shoppingBagOnClick={() => setCartModalOpen(true)}
      />
      <div className={classNames('supplements-pdp', styles.productInfo)}>
        <div className={classNames(styles.imageGalleryContainer)}>
          <ImageGallery
            media={[
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-in-app-1.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-in-app-2.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-in-app-3.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-in-app-4.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-in-app-5.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
            ]}
            squareAspectRatio
            padGallerySelectorImages
            pageDots
            defaultImageIndex={initialImageCarouselNumber}
          />
          <h1 className={styles.titleText}>Fi Supplements</h1>
          <p className={styles.descriptionText}>
            {fromInsights
              ? `Give ${petName} the extra support they need to feel great every day with our proven formulas.`
              : 'Data-backed, targeted formulas for smart daily dog care.'}
          </p>
          <div className={styles.skuOptionSelector}>
            {Object.values(SKUType).map((skuType) => (
              <SKUOption
                key={skuType}
                skuType={skuType}
                selectedSKUType={selectedSKUType}
                onSelect={setSelectedSKUType}
                fromInsights={fromInsights}
                petName={petName}
                recommended={recommendedSkuType}
              />
            ))}
          </div>
          <div className={styles.divider} />
          <PurchaseContainer
            purchaseType={purchaseType}
            setPurchaseType={setPurchaseType}
            autoshipFrequency={autoshipFrequency}
            setAutoshipFrequency={setAutoshipFrequency}
            isNextItemEligibleForFirstBagDiscount={isNextItemEligibleForFirstBagDiscount}
            fromInsights={fromInsights}
            petName={petName}
          />
        </div>
        <div className={styles.divider} />
        <AllBenefits skuType={selectedSKUType} />
        <div className={styles.divider} />
        <Reviews />
        <div className={styles.divider} />
        <FAQ />
      </div>
      <ConfirmModal
        open={!!billingError}
        body={
          <>
            <h4>There was an issue with your payment</h4>
            <p>{billingError}</p>
          </>
        }
        trigger={<></>}
        acceptText="Update Payment"
        onAccept={() => {
          history.push({
            pathname: AppPaths.Supplements.CheckoutAddPayment,
            search: window.location.search,
            state: { isEligibleForFirstBagDiscount, purchaseType, autoshipFrequency, selectedSKUType },
          });
        }}
      />
      {!userHasPaymentInfo && (
        <div className={styles.addToCartContainer}>
          <button
            className={styles.addToCartButton}
            onClick={() => {
              history.push({
                pathname: AppPaths.Supplements.CheckoutAddPayment,
                search: window.location.search,
                state: { isEligibleForFirstBagDiscount, purchaseType, autoshipFrequency, selectedSKUType },
              });
            }}
          >
            Next
          </button>
        </div>
      )}
      {userHasPaymentInfo && (
        <InAppCheckoutModal
          open={cartModalOpen}
          onClose={() => setCartModalOpen(false)}
          cartItems={cartItems}
          trigger={
            <div className={styles.addToCartContainer}>
              <button
                className={styles.addToCartButton}
                onClick={() => {
                  setCartItems([
                    ...cartItems,
                    {
                      skuType: selectedSKUType,
                      eligibleForDiscount:
                        isNextItemEligibleForFirstBagDiscount && purchaseType === PurchaseType.Autoship,
                      purchaseType: purchaseType!,
                      frequency: autoshipFrequency,
                    },
                  ]);
                }}
              >
                Add to Cart
              </button>
            </div>
          }
          onPurchase={() => {
            purchaseMutation({
              variables: {
                input: {
                  skusToAdd: getCartSKUs(cartItems),
                },
              },
            }).catch((error: Error) => {
              // Catch billing errors and recommend a billing update change
              if (error.message.includes(RECURLY_ERROR_ADDENDUM)) {
                // Recurly billing errors are formatted as: "Error: [error message]. Please use a different card or contact your bank."
                // Send the error message without the preamble and addendum to the billing error state.
                setBillingError(error.message.replace('Error: ', '').replace(RECURLY_ERROR_ADDENDUM, ''));
              }
            });
          }}
          onRemoveItem={handleRemoveItem}
        />
      )}
      {showToast && !!toastChange && <Toast change={toastChange} multipleSubscriptionsChanged={false} />}
    </>
  );
}
