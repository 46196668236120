import { FeaturesReady } from '@growthbook/growthbook-react';
import { useMutation } from '@apollo/client';
import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useShouldShowApplePay from '../../hooks/useShouldShowApplePay';
import { updateBillingInfoMutation } from '../../graphql-operations';
import { gqlTypes } from '../../types';
import AppPaths from '../../AppPaths';
import { useCartPricing } from '../../contexts/CartPricingContext';
import useCheckoutPaths from '../../hooks/useCheckoutPaths';
import * as events from '../../lib/analytics/events';
import { useCartMode } from '../../lib/cartModes';
import CheckoutContext from '../../lib/CheckoutContext';
import ErrorMessages, { getCustomerMessageFromApolloError, logInternalError } from '../../lib/errors';
import { centsToDollars, centsToDollarsNumber } from '../../lib/util';
import * as types from '../../types';
import LoginButton from '../../views/Checkout/components/LoginButton';
import ApplePayCheckout from '../ApplePayCheckout';
import Button from '../Button';
import ErrorMessage, { useError } from '../ErrorMessage';
import { SimpleDismissableModal } from '../modals';
import PayPalButton from '../PayPalButton';
import styles from './CartSummary.module.scss';
import { showNanoVariant } from '../NanoBanner/NanoBanner';
import { couponWaivesActivationFee, inCartS3CollarCount } from '../ItemsInCart/CouponLineItemV2';
import classNames from 'classnames';
import { getFiGrowthBook } from '../../lib/growthbook';
import { householdDiscountReason } from '../MultidogDiscount/MultidogDiscountExistingCollars';
import { cartOnlyHasGiftItems } from '../../lib/cart';
import { fetchRecaptchaToken } from '../../lib/recaptcha';

type OnCheckoutFn = (checkoutCart: types.Cart, cartPricing: types.CartPricing) => void;
interface CartActionProps {
  onCheckOut: OnCheckoutFn;
  showDigitalWallets: boolean;
}

interface PaymentOptionsProps {
  onCheckOut: OnCheckoutFn;
  showDigitalWallets: boolean;
  digitalWalletsOnly: boolean;
}

export function getCartV2FormattedTotal(cents: number): string {
  const hasNonZeroOnesOrTensDigit = (num: number): boolean => num % 10 !== 0 || Math.floor(num / 10) % 10 !== 0;
  if (hasNonZeroOnesOrTensDigit(cents)) {
    return centsToDollars(cents);
  }
  return centsToDollarsNumber(cents).toFixed(0);
}

function TotalPrice() {
  const cartPricing = useCartPricing();
  const { totalInCents, cartItemCredits } = cartPricing;
  const { cart } = useContext(CheckoutContext);
  const cartV2Enabled = showNanoVariant() || getFiGrowthBook().getFeatureValue('nano-ecom-test-1', false);

  if (cartV2Enabled) {
    const creditDiscount = Object.values(cartItemCredits || []).reduce(
      (acc, credits) => acc + credits.reduce((accCredit, credit) => accCredit + credit.creditAmountInCents, 0),
      0,
    );
    const couponApplied = cart.couponCode !== undefined;
    const referralCodeApplied = cart.referralCode !== undefined && !householdDiscountReason(cartPricing);

    // Experiment: 'nano99' is a coupon code that applies a discount directly to the pre-paid amount
    // While keeping the activation fee. Force the discount amount to display in the rendered component.
    const elevatedNanoCodeApplied = cart.couponCode === 'nano99';
    const cartItems = Object.values(cart.cartItems);
    const totalCollarCount = inCartS3CollarCount(cartItems);
    const monthToMonthCollarCount = inCartS3CollarCount(cartItems, 'sub-monthly-1m-001');
    const activationFeeDiscount = inCartS3CollarCount(cartItems) * 20 * 100 || 0;
    const showActivationFeeDiscount =
      ((couponApplied && couponWaivesActivationFee(cartPricing, cart)) ||
        referralCodeApplied ||
        elevatedNanoCodeApplied) &&
      activationFeeDiscount > 0 &&
      totalCollarCount > 0 &&
      totalCollarCount - monthToMonthCollarCount > 0;

    // The elevated nano code applies a discount to the pre-paid amount, but not the activation fee.
    const totalDiscount = elevatedNanoCodeApplied ? creditDiscount : activationFeeDiscount + creditDiscount;
    const originalTotalInCents = totalDiscount + totalInCents;

    return (
      <div className={classNames(styles.nanoTotalPrice, styles.priceData)}>
        <div>Total</div>
        <div className={styles.pricingContainer}>
          {showActivationFeeDiscount && (
            <div className={styles.originalPriceValue}>${getCartV2FormattedTotal(originalTotalInCents)}</div>
          )}
          <div className={styles.priceValue}>${getCartV2FormattedTotal(totalInCents)}</div>
          {showActivationFeeDiscount && (
            <div className={styles.dolalrsOffValue}>${getCartV2FormattedTotal(totalDiscount)} OFF</div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.priceData}>
      <div>Total</div>
      <div className={styles.priceValue}>${centsToDollars(totalInCents)}</div>
    </div>
  );
}

export function PaymentOptions({ onCheckOut, showDigitalWallets, digitalWalletsOnly }: PaymentOptionsProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const showApplePay = useShouldShowApplePay();
  const cartPricing = useCartPricing();
  const { cart, checkoutActions, session } = useContext(CheckoutContext);
  const checkoutPaths = useCheckoutPaths();
  const { applePayErrorAlertWhenAccountExists, showPayPal } = useCartMode();
  const { clearError, error, errorID, setError } = useError();

  const onStartPayPal = useCallback(clearError, [clearError]);

  const [mutation, { loading: mutationLoading }] = useMutation<
    gqlTypes.updateBillingInfo,
    gqlTypes.updateBillingInfoVariables
  >(updateBillingInfoMutation);

  const onCompletePayPal = useCallback(
    (token: types.BillingToken) => {
      // Prevent double-submit if an existing mutation request is already in progress
      if (mutationLoading) {
        return;
      }

      const isLoggedIn = !!session;
      if (isLoggedIn) {
        fetchRecaptchaToken('payment_add')
          .then((recaptchaToken) =>
            mutation({
              variables: { input: { billingInfo: { token: token.id } }, recaptchaToken },
            }),
          )
          .then((result) => {
            const newBillingInfo = result.data?.updateBillingAccount?.billingInfo;
            if (newBillingInfo) {
              dispatch(checkoutActions.setBillingInfo({ billingInfo: newBillingInfo }));
              events.payment.paypalTokenSuccess();
              history.push(checkoutPaths.Shipping);
            } else {
              throw new Error('No updated billing information');
            }
          })
          .catch((err) => {
            const customerMessage = getCustomerMessageFromApolloError(err);
            if (customerMessage) {
              setError(customerMessage);
            } else {
              logInternalError(err, {
                fingerprint: ['{{ default }}', err.message],
                tags: {
                  paymentMethod: 'paypal',
                  checkoutStep: 'updateBillingInfo',
                },
              });
              setError(ErrorMessages.DEFAULT);
            }

            events.payment.paypalTokenError(err.message);
          });
      } else {
        // If we're not logged in, we have to update billing info with the token after they create the account
        events.payment.paypalTokenSuccess();
        history.push(checkoutPaths.Shipping, { billingToken: token.id });
      }
    },
    [checkoutActions, checkoutPaths.Shipping, dispatch, history, mutation, mutationLoading, session, setError],
  );

  const onError = useCallback(
    (err: Error) => {
      setError(err.message);
    },
    [setError],
  );

  return (
    <div className={styles.cartPayment}>
      {!digitalWalletsOnly && <TotalPrice />}
      <div className={styles.summaryActions}>
        {!digitalWalletsOnly && (
          <Button
            className={styles.checkoutButton}
            onClick={(e) => {
              onCheckOut(cart, cartPricing);
              history.push(checkoutPaths.Shipping);
            }}
          >
            {!showDigitalWallets ? 'Checkout' : 'Pay with card'}
          </Button>
        )}
        {showApplePay && showDigitalWallets && (
          <ApplePayCheckout
            errorAlertOnAccountExists={applePayErrorAlertWhenAccountExists}
            events={events.cartPage}
            onError={onError}
          />
        )}
        {showPayPal && showDigitalWallets && (
          <PayPalButton
            events={events.cartPage}
            onComplete={onCompletePayPal}
            onError={onError}
            onStart={onStartPayPal}
          />
        )}
      </div>
      {error && (
        <div className={styles.summaryError}>
          <ErrorMessage errors={[error]} errorID={errorID} />
        </div>
      )}
    </div>
  );
}

export default function CartActions({ onCheckOut, showDigitalWallets }: CartActionProps) {
  const { session, cart } = useContext(CheckoutContext);
  const isOnlyGiftingCart = cartOnlyHasGiftItems(cart);

  return (
    <FeaturesReady>
      <div className={styles.desktopCartActions}>
        <PaymentOptions onCheckOut={onCheckOut} showDigitalWallets={!isOnlyGiftingCart} digitalWalletsOnly={false} />
      </div>
      <div className={styles.footer}>
        {!session && (
          <div className={styles.loginButton}>
            <LoginButton returnTo={AppPaths.Bag} />
          </div>
        )}
      </div>
      <div className={styles.mobileActionSheet}>
        {showDigitalWallets && (
          <>
            <TotalPrice />
            <SimpleDismissableModal trigger={<Button type="button">Checkout</Button>}>
              <PaymentOptions onCheckOut={onCheckOut} showDigitalWallets={true} digitalWalletsOnly={false} />
            </SimpleDismissableModal>
          </>
        )}
        {!showDigitalWallets && (
          <PaymentOptions onCheckOut={onCheckOut} showDigitalWallets={false} digitalWalletsOnly={false} />
        )}
      </div>
    </FeaturesReady>
  );
}
